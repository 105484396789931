import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import Programs from './components/programs';
import News from './components/news';
import Contact from './components/contact';
import Blog from "./components/blog";
import Program from './components/program';

import Admin_dashboard from './admin';
import NEWS from './admin/components/news/news';
import CreateNews from './admin/components/news/create_news';
import EditNews from './admin/components/news/edit_news';
import Team from './admin/components/Team/team';
import AddTeam from './admin/components/Team/add_team';
import Admin_programs from './admin/components/programs/programs';
import CreatePrograms from './admin/components/programs/create_programs';
import EditPrograms from './admin/components/programs/edit_programs';
import Testimonials from './admin/components/Testimonials/testimonials';
import AddTestimonials from './admin/components/Testimonials/add_testimonial';
import Galleries from './admin/components/Gallery/galleries';
import AddGAllery from './admin/components/Gallery/add_gallerry';
import Banner_slider from './admin/components/Banner_Slider/banner_slider';
import AddBannerSlider from './admin/components/Banner_Slider/add_banner_slider';
import Messages from './admin/components/comments/comments';
import Partners from './admin/components/Partners/partners';
import AddPartner from './admin/components/Partners/add_partners';
import AdmDashboard from './admin/dashboard';
import RegisterAdmin from './admin/add_admin';
import UpdateAdmin from './admin/update_admin';
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/blog/:id' element={<Blog/>}></Route>
          <Route path='/about' element = {<About/>}></Route>
          <Route path='/programs' element = {<Programs/>}></Route>
          <Route path='/news' element = {<News/>}></Route>
          <Route path='/contacts' element = {<Contact/>}></Route>
          <Route path='/program/:id' element = {<Program/>}></Route>

          <Route path='/admin/swcologin' element = {<Admin_dashboard/>}></Route>
          <Route path='/admin/NEWS' element={<NEWS/>}></Route>
          <Route path='/admin/Dashboard' element={<Admin_dashboard/>}></Route>
          <Route path='/admin/createnews' element={<CreateNews/>}></Route>
          <Route path='/admin/editnews/:id' element={<EditNews/>}></Route>
          <Route path='/admin/Team' element={<Team/>}></Route>
          <Route path='/admin/add_team' element={<AddTeam/>}></Route>
          <Route path='/admin/Programs' element={<Admin_programs/>}></Route>
          <Route path='/admin/create_program' element={<CreatePrograms/>}></Route>
          <Route path='/admin/edit_program/:id' element={<EditPrograms/>}></Route>
          <Route path='/admin/Testimonials' element={<Testimonials/>}></Route>
          <Route path='/admin/add_testimonials' element={<AddTestimonials/>}></Route>
          <Route path='/admin/Gallery' element={<Galleries/>}></Route>
          <Route path='/admin/Partners' element={<Partners/>}></Route>
          <Route path='/admin/add_gallery' element={<AddGAllery/>}></Route>
          <Route path='/admin/Banner' element={<Banner_slider/>}></Route>
          <Route path='/admin/add_banner_slider' element={<AddBannerSlider/>}></Route>
          <Route path='/admin/Comments' element={<Messages/>}></Route>
          <Route path='/admin/add_partner' element={<AddPartner/>}></Route>
          <Route path='/admin/adm_dashboard' element={<AdmDashboard/>}></Route>
          <Route path='/admin/Settings' element={<AdmDashboard/>}></Route>
          <Route path='/admin/add_admin' element={<RegisterAdmin/>}></Route>
          <Route path='admin/update' element={<UpdateAdmin/>}></Route>

          


          

          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
