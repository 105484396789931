import React, { Component } from 'react'


function Map(){
    return (
        <div>
             <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d506598.88125685113!2d36.239074!3d7.263161000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17aebdc5a0ab8dfb%3A0x5a5f2f023750854c!2sBONGA%20Stadium!5e0!3m2!1sen!2sus!4v1714499696794!5m2!1sen!2sus"
        width="600"
        height="350"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>

        </div>
    )
}

export default Map;