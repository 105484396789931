import React, { useEffect, useState } from 'react';
import '../css/blogview.css';
import Navbar from './navbarComp';
import Footer from './footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { ServerUrl } from '../helper/serverUrl';

const Blog = () => {
    const [news_title, setTitle] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [imageUrl, setImageUrl] = useState('');
    const { id } = useParams();

    useEffect(() => {
        axios
            .get(`${ServerUrl}/blog/` + id)
            .then((res) => {
                setTitle(res.data[0].news_title);
                // Fetch and set content for editorState
                const contentState = convertFromRaw(JSON.parse(res.data[0].news_description));
                setEditorState(EditorState.createWithContent(contentState));
                // Set imageUrl directly if available
                setImageUrl(`${ServerUrl}/images/${res.data[0].newsimage}`);
                // Set date from database
            })
            .catch((err) => console.log(err));
    }, [id]);

    return (
        <div>
            <Navbar />
            <div className="container">
                <h1 className="primary-heading">{news_title}</h1>
                <div className="imageContainer">
                    <img src={imageUrl} alt="Image" className="image" />
                </div>
                <div className="descriptionContainer">
                    <Editor editorState={editorState} readOnly={true} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blog;
