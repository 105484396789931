import React from "react";
import "../css/focus_area.css"; // Import the CSS file for styling
import focus_area from '../images/focusArea.png';
import list_icon from '../images/list_icon.png';

const FocusArea = () => {
    return (
        <div className="focus-area-container">
            <img src={focus_area} alt="Focus Area" />
            <div className="focus-area-content">
                <h3>Our Focus Areas</h3>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Human Rights and Promotion of Good Governance (Cross-Cutting Issues)</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Education with a focus on Rural girls, Ethnic minorities, and Person with disability</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Socio-economic inclusion of Gender and Ethnic Minorities</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Peace building and Conflict Resolution</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Environmental Conservation and Environmental-friendly livelihood</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Sexual and Reproductive health rights</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Water and Sanitation</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Young People</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Support for Person with disability</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Internally Displaced Persons, Migrants, and Refugees</p>
                </div>
                <div className="focus-list">
                    <img src={list_icon} alt="List Icon" />
                    <p>Preventive and Curative health for Children and elderly people</p>
                </div>
            </div>
        </div>
    );
};

export default FocusArea;
