import './App.css';
import Sidebar from './layout/Sidebar/Sidebar';
import Content from './layout/Content/Content';
import './dashboard_style.css'
import { Link } from 'react-router-dom';

function AdmDashboard() {
  return (
    <>
      <div className='app'>
        <Sidebar />
        {/** <Content />  */}

        <div className="head-container">
      <h1 className="head-title">Admin Control Page</h1>
      <div className="button-container">
        <Link to = '/admin/add_admin'className="admin-button add-button">Add User</Link>
        <Link to = '/admin/update' className="admin-button edit-button">Edit Admin</Link>
      </div>
    </div>
 </div>

    </>
  )
}

export default AdmDashboard
