import React, { useState, useEffect } from "react";
import "../css/navbar.css";
import logoimg from '../images/logo.png';
import { Link } from "react-router-dom";
import axios from 'axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { ServerUrl } from "../helper/serverUrl";

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");

  const navToggle = () => {
    setActive(active === "nav__menu" ? "nav__menu nav__active" : "nav__menu");
    setIcon(icon === "nav__toggler" ? "nav__toggler toggle" : "nav__toggler");
  };

  const [program, setProgram] = useState([]);

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/Programs/`)
      .then(res => {
        const formattedProgram = res.data.map(item => {
          console.log('Raw program_description:', item.program_description);
          let programDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
          try {
            // Try parsing the news_description JSON
            const parsedDescription = JSON.parse(item.news_description);
            // If parsing succeeds, create EditorState from the parsed data
            if (parsedDescription) {
              programDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
            }
          } catch (error) {
            // Log parsing errors
            console.error('Error parsing program_description:', error);
          }
          console.log('Parsed program_description EditorState:', programDescriptionEditorState);
          return {
            ...item,
            date: new Date(item.date).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
            program_description: programDescriptionEditorState
          };
        });
        setProgram(formattedProgram);
      })
      .catch(err => console.log(err));
  }, []);


  return (
    <nav className="nav">
      <div className="nav__brand">
        <a href="/">
          <img src={logoimg} />
        </a>
        <a href="/" className="brand_title">
          South West Charitable Organization
        </a>
        <a href="/" className="brand_title_short">
          SWCO
        </a>
      </div>

      <ul className={active}>
        <li className="nav__item">
          <a href="/" className="nav__link">
            Home
          </a>
        </li>
        <li className="nav__item">
          <a href="/about" className="nav__link">
            About
          </a>
        </li>
        <li className="nav__item">
          <a className="nav__link">
            Programs
          </a>




          <ul className="dropdown">
            <li className="dropdown__item"><a href="/programs/program1" className="dropdown__link"><br></br></a></li>
            {program.map((service, index) => (
              <li className="dropdown__item" key={index}>
                <a href={`/program/${service.program_id}`} className="dropdown__link" >{service.program_title}</a>
              </li>


            ))}
          </ul>
        </li>
        <li className="nav__item">
          <a href="/news" className="nav__link">
            Blog
          </a>
        </li>
        <li className="nav__item">
          <a href="/#partners" className="nav__link">
            Partners
          </a>
        </li>
        <li className="nav__item">
          <a href="/contacts" className="nav__link">
            Contact
          </a>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
