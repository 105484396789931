import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ServerUrl } from '../../../helper/serverUrl';

function Galleries() {
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/Gallery/`)
      .then(res => {
        const formattedgallery = res.data.map(item => {

          return {
            ...item,

          };
        });
        setGalleries(formattedgallery);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${ServerUrl}/admin/Gallery/` + id)
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='app'>
      <Sidebar />
      <div className="admin-content-view-container">
        <div className="header">
          <h1>Galleries</h1>
          <Link to="/admin/add_gallery" className="create-more-content-button">
            <FaPlus />
            Add Gallery
          </Link>
        </div>
        <div className="content-body-container">
          {galleries.map((item, index) => (
            <div className="content-view-item" key={index}>
              <div className="view-content-image-container">
                <img src={`${ServerUrl}/images/${item.image}`} alt="team" />
              </div>
              <div className="view-content-details">
                <p style={{ paddingTop: '10px' }}>{item.gallery_tag}</p>


                <div className="content-actions">

                  <button className="content-delete-button" onClick={e => handleDelete(item.gallery_id)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Galleries;
