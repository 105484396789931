import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the default styles
import '../css/Gallerry.css'; // Import custom CSS for gallery styling
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ServerUrl } from '../helper/serverUrl';

const Gallery = () => {
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/Banner/`)
      .then(res => {
        const formattedGallery = res.data.map(item => {
          return {
            original: `${ServerUrl}/images/${item.image}`,
            description: item.gallery_tag
          };
        });
        setGalleries(formattedGallery);
      })
      .catch(err => console.log(err));
  }, []);

  // Custom styles for image gallery
  const galleryStyles = {
    borderRadius: 10, // Set border radius for rounded rectangle
    backgroundColor: '#f0f0f0', // Set background color behind images
  };

  return (
    <div className="gallery-container">
      <div>
      </div>
      <ImageGallery
        items={galleries}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={true}
        additionalClass="rounded-images"
        slideInterval={5000}
        slideDuration={1000}
        slideOnThumbnailOver={true}
        lazyLoad={true}
        showNav={true}
        showBullets={true}
        autoPlay={true}
        style={galleryStyles}
      />
      <div><p style={{ color: 'white', background: 'rgb(3, 67, 131)', padding: '10px', borderTopLeftRadius: '15px', borderBottomRightRadius: '15px', marginTop: '10px' }}>Education is the most powerful weapon which you can use to change the world</p></div>

    </div>
  );
};

export default Gallery;
