import React, { useEffect, useState, useContext } from 'react';
import { personsImgs } from '../../utils/images';
import { navigationLinks } from '../../data/data';
import "./Sidebar.css";
import { SidebarContext } from '../../context/sidebarContext';
import axios from 'axios';

const Sidebar = () => {
  const [activeLinkIdx, setActiveLinkIdx] = useState(1);
  const [sidebarClass, setSidebarClass] = useState("");
  const [unreadComments, setUnreadComments] = useState(0);
  const { isSidebarOpen } = useContext(SidebarContext);

  useEffect(() => {
    axios.get('http://localhost:8081/admin/unread_comment/')
      .then(res => {
        console.log(res.data[0].unread_message);
        // Assuming the response has the count of unread comments
        setUnreadComments(res.data[0].unread_message);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (isSidebarOpen) {
      setSidebarClass('sidebar-change');
    } else {
      setSidebarClass('');
    }
  }, [isSidebarOpen]);

  const handleResetUnreadComments = () => {
    axios.post('http://localhost:8081/admin/update_unread_comments', {})
      .then(res => {
        console.log('Unread comments reset successfully');
      })
      .catch(err => console.log(err));
  };

  return (
    <div className={`sidebar ${sidebarClass}`}>
      <div className="user-info">
        <div className="info-img img-fit-cover">
          <img src={personsImgs.person_two} alt="profile image" />
        </div>
        <span className="info-name">Mr. Admin</span>
      </div>

      <nav className="navigation">
        <ul className="nav-list">
          {navigationLinks.map((navigationLink) => (
            <li className="nav-item" key={navigationLink.id}>
              <a 
                href={`/admin/${navigationLink.title}`} 
                className={`nav-link ${navigationLink.id === activeLinkIdx ? 'active' : ''}`}
                onClick={() => setActiveLinkIdx(navigationLink.id)} // Update the active link index on click
              >
                <img src={navigationLink.image} className="nav-link-icon" alt={navigationLink.title} />
                <span className="nav-link-text">{navigationLink.title}</span>
                {navigationLink.title === 'Comments' && unreadComments > 0 && (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>({unreadComments})</span>
                )}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      {/* Button to reset unread comments */}
      
    </div>
  );
}

export default Sidebar;
