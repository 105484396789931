import React, { useEffect } from "react";
import Navbar from "./navbarComp";
import '../App.css';
import logoimg from '../images/logo.png';
import HomeService from "../components/home_service";
import PriorityComponent from "../components/priorityComp";
import How2Participate from "../components/how2participate";
import TestimonialSlider from "../components/testimonials";
import Gallery from "../components/gallerries";
import Footer from "../components/footer";
import ImageSlider from "./kpimageSlider";
import Partners from "./partners";
import { Link } from "react-router-dom";
import MissionVission from "./mission_vision";
import FocusArea from "./focus_area";

function Home() {

  useEffect(() => {
    // Check if the URL contains the hash for the 'partners' section
    const hash = window.location.hash;
    if (hash === '#partners') {
      const element = document.getElementById('partners');
      if (element) {
        element.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, []);
  const containerStyles = {
    position: "relative",
    width: "100%",
    height: "700px",
    maxHeight: "calc(100vh - 50px)", // Adjust the maximum height to fit the available viewport minus the menu bar height
    overflow: "hidden",
    marginBottom: "20px", // Add margin bottom to create space below the container
    padding:'50px',
    backgroundSize: "cover",
  };

  const bgcolor = {
    background: 'rgba(221, 221, 238,1)',
  };

  return (
    <div style={bgcolor}>
      <Navbar />
      <div >
        <br></br>
        <br></br>
        <br></br>

        <ImageSlider />
      </div>

      <div className="home-about-container">
        <div className="home-text-section">
          <h1 className="primary-heading">About Us</h1>
          <p className="primary-text">
            South West Charitable Organization (SWCO), founded in 2014 as an Ethiopian NGO, is dedicated to ensuring universal access to basic services and rights for all.
            SWCO focuses on underserved populations such as the Manja community, people with disabilities,
            rural girls, orphaned children, women breadwinners, and ethnic minorities in Ethiopia's southwest.
            Initiatives include human rights advocacy, governance, conflict resolution, and peacebuilding.
            SWCO is registered as a non-profit with Ethiopian regulatory agencies since 2016 and 2019.
          </p>
          <Link to='/about' className="secondary-button">Read More</Link>
        </div>
        <div className="home-about-image-section">
          <a href="/"><img src={logoimg} alt="logo" /></a>
        </div>
      </div>


     
      <div><MissionVission /></div>
      <div><FocusArea/></div>
      {/**<div>
        <HomeService /> // this is our interested program conent
      </div> */}

      {/* <div>
        <PriorityComponent />
        <How2Participate />
      </div> */}

      {/** <div>
        <TestimonialSlider />
      </div> */}
      <div id="partners">
        <Partners />
      </div>
      <div>
       
        <Footer />
      </div>
    </div>
  );
}

export default Home;
