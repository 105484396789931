import React, { useState } from 'react';
import '../css/contact.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaTelegram } from 'react-icons/fa'; // Import icons
import Map from './map';
import Navbar from './navbarComp';
import Footer from './footer';
import bnrimage from '../images/contacBanner2.png'
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import axios from 'axios';
import { ServerUrl } from '../helper/serverUrl';

const ContactPage = () => {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Comment:', comment);

        try {
            const response = await axios.post(`${ServerUrl}/admin/comment`, {
                name,
                email,
                comment
            });
            console.log('Response:', response.data);
            // Show confirmation message
            setShowConfirmation(true);
            // Hide confirmation message after 3 seconds
            setTimeout(() => setShowConfirmation(false), 3000);
        } catch (error) {
            console.error('There was an error sending the comment:', error);
        }

        // Reset form fields
        setName('');
        setEmail('');
        setComment('');
    };

    return (
        <div>
            <div><Navbar /></div>
            <div className="contact-banner">
                <img src={bnrimage} alt="Banner" />
                <div className="contact-banner-text"></div>
            </div>
            <div className="contact-container">
                <div className="map-container">
                    <Map />
                    <div className="contact-info">
                        <div className="info-item">
                            <FaMapMarkerAlt className="icon" />
                            <p>Bonga, Kaffa, Ethiopia</p>
                            <FaPhone className="phone" />
                            <p>+251911386863, +251923344364</p>
                        </div>
                        <div className="info-item">
                            <a href="https://www.facebook.com/SWCO.Bonga" className='info-item-social'>
                                <FaFacebook />
                            </a>
                            <a href="https://swcobonga.org/contact/" className='info-item-social'>
                                <FaTwitter />
                            </a>
                            <a href="https://t.me/swcobonga" className='info-item-social'>
                                <FaTelegram />
                            </a>
                            <a href='mailto:swco3565@gmail.com' className='info-item-social'>
                                <FaEnvelope />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="comment-section">
                    <h2 className='comment-header'>Message With Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Full Name *</label>
                            <input
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                required
                                placeholder='Firstname Lastname'
                            />
                        </div>
                        <div className="form-group">
                            <label>Email Address *</label>
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                placeholder='example@gmail.com'
                            />
                        </div>
                        <div className="form-group">
                            <label>Message *</label>
                            <textarea
                                placeholder="Write your comment here..."
                                value={comment}
                                onChange={handleCommentChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="comment-button">Send Message</button>
                    </form>
                    {showConfirmation && (
                        <div className="confirmation-message">
                            <center><p style={{ paddingTop: '10px' }}>Thank you for your message!</p></center>
                        </div>
                    )}
                </div>
            </div>
            <div><Footer /></div>
        </div>
    );
};

export default ContactPage;
