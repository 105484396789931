import React from "react";
import "../css/mission_vision.css"; // Import the CSS file for styling
import mission from '../images/mission.svg';
import vision from '../images/vision.svg';

const MissionVission = () => {
    return (
        <div className="mission-vision-container">
            <div className="mission-vision-section">
                <img src={mission} alt="Our Mission" />
                <h3>Our Mission</h3>
                <p>Through a Right-Based, participatory, and integrated community development approach, SWCO aims to enhance the well-being of underprivileged people, especially: Ethnic minorities, women, Children, Youth, Persons with a disability, IDPs, Refugees and Other vulnerable groups.</p>
            </div>
            <div className="mission-vision-section">
                <img src={vision} alt="Our Vision" />
                <h3>Our Vision</h3>
                <p>The South West Charitable Organization envisions a society in which people are happy, dignified, peaceful, healthy, productive, and self-governing.</p>
            </div>
        </div>
    );
};

export default MissionVission;
