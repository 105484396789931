import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { ServerUrl } from '../../../helper/serverUrl';

function AddPartner() {
    const [organization, setOrganization] = useState('');
    const [organization_link, setOrganization_link] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };


    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('organization', organization);
        formData.append('organization_link', organization_link);


        formData.append('image', image);

        axios.post(`${ServerUrl}/admin/add_partner`, formData)
            .then(res => {
                console.log(res);
                navigate('/admin/Partners')
            })
            .catch(err => console.log(err));
    }

    return (
        <div className="app">
            <div><Sidebar /></div>
            <div className="create-content-container ">
                <h1 style={{ paddingBottom: '10px' }}>Add Partner</h1>
                <form onSubmit={handleSubmit}>
                    <div className="content-form-group">
                        <label>Partner Name:</label>
                        <input
                            type="text"
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                            placeholder='Name of the organization'
                            required
                        />
                    </div>
                    <div className="content-form-group">
                        <label>Partner Link:</label>
                        <input
                            type="url"
                            value={organization_link}
                            onChange={(e) => setOrganization_link(e.target.value)}
                            placeholder='Put link of the organization'
                        />
                    </div>



                    <div className="content-form-group">
                        <label>Image:</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} />
                        {imageUrl && (
                            <img src={imageUrl} alt="Selected" className="selected-image" />
                        )}
                    </div>
                    <button type="submit" className="content-primary-button">
                        Add Team
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddPartner;
