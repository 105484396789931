import React from 'react';
import '../css/AboutPage.css';
import Navbar from './navbarComp';
import bnrimage from '../images/about_us.jpg'
import about_image from '../images/about_image.jpg'
import prfl from '../images/john.jpeg'
import Footer from './footer';
import Team from './team';
import OurCompany from './ourcompany(visnMision)';

const About = () => {
  return (

    <div className="about-page-style">
       <Navbar/>
      <div className="the-banner">
        <img src={bnrimage} alt="Banner" />
        <div className="the-banner-text">About Us</div>
      </div>
      <div className="the-about-content-container">
        <div className="the-about-left-section">
          <img src= {about_image} alt="Company" />
        </div>
        <div className="the-about-right-section">
          <h2 className='primary-heading'>Who we are</h2>
          <p className='primary-text'>
          South West Charitable organization (SWCO) was founded in 2014 as an Ethiopian non-governmental 
          organization. SWCO is a registered non-profit in Ethiopia, first with the Ethiopian Charity and 
          Society Agency in January 2016, and then again with the newly formed Agency for Civil Society 
          Organization in 2019.
          </p>
          <p className='primary-text'>
          The core belief of the organization’s founders, which led to its establishment, was that all 
          human beings, regardless of their backgrounds, deserve to have guaranteed access to basic services,
           opportunities for socio-economic development, and rights that are respected. This belief led to the
            establishment of the organization.
          </p>
          <p className='primary-text'>
          We plan to close the gap that exists within Ethiopia by taking actions that are centered on the
           underserved and underrepresented populations of the society. The Manja community, in general, 
           is one of these populations, as are people with disabilities, rural girls, orphaned children, 
           women who are the primary breadwinners in their households, human rights promotion and access 
           to justice, governance issues, conflict transformation and peace dialogues, people with disabilities,
            and ethnic minorities residing in Ethiopia’s southwest region. 
          </p>
        
          

        </div>
      </div>
      <div><Team/></div>
      {/* <div><OurCompany/></div> */}
      <div><Footer/></div>

      

    </div>
  );
};

export default About;
