import React, { useState, useEffect } from 'react';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ServerUrl } from '../../../helper/serverUrl';

function Banner_slider() {
  const [slider, setSlider] = useState([]);

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/Banner/`)
      .then(res => {
        const formattedslider = res.data.map(item => {

          return {
            ...item,

          };
        });
        setSlider(formattedslider);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${ServerUrl}/admin/Banner/` + id)
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='app'>
      <Sidebar />
      <div className="admin-content-view-container">
        <div className="header">
          <h1>Banner Sliders</h1>
          <Link to="/admin/add_banner_slider" className="create-more-content-button">
            <FaPlus />
            Add Banner Slider
          </Link>
        </div>
        <div classNames="content-body-container">
          {slider.map((item, index) => (
            <div className="content-view-item" key={index}>
              <div className="view-content-image-container">
                <img src={`${ServerUrl}/`} alt="slider" />
              </div>
              <div className="view-content-details">
                {/**
                <p style={{paddingTop:'10px'}}>{item.slider_description}</p>}
                * 
                */}


                <div className="content-actions">

                  <button className="content-delete-button" onClick={e => handleDelete(item.slider_id)}>
                    <FaTrash /> Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner_slider;
