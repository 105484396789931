import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './update_admin.css';
import adminIcon from './admin_icon.png'; // Ensure you have an admin icon image in your project
import { useNavigate } from 'react-router-dom';
import { ServerUrl } from '../helper/serverUrl';
const UpdateAdmin = () => {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${ServerUrl}/admin/user`);
        console.log('User data:', response.data); // Log user data for debugging
        if (response.data.success) {
          setUsername(response.data.user.username);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setError('An error occurred at fetching. Please try again.');
      }
    };
    fetchUser();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Clear password fields
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    try {
      const response = await axios.post(`${ServerUrl}/admin/update`, {
        username,
        oldPassword,
        newPassword,
      });
      console.log('Update response:', response.data); // Log response data for debugging
      if (response.data.success) {
        setSuccess('User updated successfully');
        setError('');
        navigate('/admin/adm_dashboard');
      } else {
        setError(response.data.message);
        setSuccess('');
      }
    } catch (error) {
      console.error('Update error:', error);
      setError('An error occurred at insertion. Please try again.');
      setSuccess('');
    }
  };

  return (
    <div className="update-user-container">
      <div className="update-user-form">
        <img src={adminIcon} alt="Admin Icon" className="admin-icon" />
        <h2>Edit Admin</h2>
        <form onSubmit={handleUpdate}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="upd-admin-input"
          />
          <input
            type="password"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            className="upd-admin-input"
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="upd-admin-input"
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="upd-admin-input"
          />
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <button type="submit" className="upd-admin-button">Update</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateAdmin;
