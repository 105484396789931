import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { ServerUrl } from '../../../helper/serverUrl';

function AddBannerSlider() {
    const [slider_title, setTitle] = useState('');
    const [slider_description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const navigate = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };



    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData();
        {/**
            formData.append('slider_title', slider_title);
        formData.append('slider_description', slider_description); */}
        formData.append('image', image);

        axios.post(`${ServerUrl}/admin/add_banner_slider`, formData)
            .then(res => {
                console.log(res);
                navigate('/admin/Banner')
            })
            .catch(err => console.log(err));
    }

    return (
        <div className="app">
            <div><Sidebar /></div>
            <div className="create-content-container ">
                <h1 style={{ paddingBottom: '10px' }}>Add Banner</h1>
                <form onSubmit={handleSubmit}>
                    {/**
                     * <div className="content-form-group">
                        <label>Title:</label>
                        <input
                            type="text"
                            value={slider_title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder='tiltle of the slider'
                            required
                        />
                    </div>
                    <div className="content-form-group">
                        <label>Short Description:</label>
                        <input
                            type="text"
                            value={slider_description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder='short description of the title'
                            required
                        />
                    </div>
                     */}

                    <div className="content-form-group">
                        <label>Image:</label>
                        <input type="file" accept="image/*" onChange={handleImageChange} />
                        {imageUrl && (
                            <img src={imageUrl} alt="Selected" className="selected-image" />
                        )}
                    </div>
                    <button type="submit" className="content-primary-button">
                        Add Banner
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddBannerSlider;
