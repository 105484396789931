import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { ServerUrl } from '../../../helper/serverUrl';

function EditPrograms() {
  const [program_title, setTitle] = useState('');
  const [program_highlight, setHighlight] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [date, setDate] = useState(new Date());
  const { id } = useParams();

  /** const handleImageChange = (e) => {
     const file = e.target.files[0];
     setImage(file);
     // Constructing the image URL from the public directory
     const imageUrl = URL.createObjectURL(file);
     setImageUrl(imageUrl);
   }; */
  const navigate = useNavigate()

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/updateProgram/` + id)
      .then(res => {
        setTitle(res.data[0].program_title);
        setHighlight(res.data[0].program_highlight);
        // Fetch and set content for editorState
        const contentState = convertFromRaw(JSON.parse(res.data[0].program_description));
        setEditorState(EditorState.createWithContent(contentState));
        // Set imageUrl directly if available
        setImageUrl(`${ServerUrl}/images/${res.data[0].program_image}`);
        // Set date from database
        setDate(new Date(res.data[0].date));
      })
      .catch(err => console.log(err));
  }, [id])

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('program_title', program_title);
    formData.append('program_highlight', program_highlight);
    formData.append('editorState', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    formData.append('image', image);

    axios.put(`${ServerUrl}/admin/edit_program/` + id, formData)
      .then(res => {
        console.log(res);
        navigate('/admin/Programs')
      })
      .catch(err => console.log(err));
  }

  return (
    <div className="app">
      <div><Sidebar /></div>
      <div className="create-content-container">
        <h1>Edit Programs</h1>
        <form onSubmit={handleSubmit}>
          <div className="content-form-group">
            <label>Title:</label>
            <input
              type="text"
              value={program_title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="content-form-group">
            <label>Highlight:</label>
            <input
              type="text"
              value={program_highlight}
              onChange={(e) => setHighlight(e.target.value)}
              required
            />
          </div>



          {
            /**
             *  <div className="form-group">
            <label>Date:</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="MMMM d, yyyy"
              className="date-picker"
            />
          </div>
             */
          }
          <div className="content-form-group">
            <label>Description:</label>
            <div className="content-editor-container">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </div>
          </div>
          <div className="content-form-group">
            <label>Image:</label>
            <input type="file" accept="image/*" onChange={handleImageChange} required />
            {imageUrl && (
              <img src={imageUrl} alt="Selected" className="selected-image" />
            )}


          </div>
          <button type="submit" className="content-primary-button">
            Update Program
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditPrograms;
