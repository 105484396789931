import React, { useState, useEffect } from 'react';
import './news.css';
import Sidebar from '../../layout/Sidebar/Sidebar';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import Content from '../../layout/Content/Content';
import { ServerUrl } from '../../../helper/serverUrl';

function Admin_NEWS() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get(`${ServerUrl}/admin/NEWS/`)
      .then(res => {
        const formattedNews = res.data.map(item => {
          console.log('Raw news_description:', item.news_description);
          let newsDescriptionEditorState = EditorState.createEmpty(); // Default to empty EditorState
          try {
            // Try parsing the news_description JSON
            const parsedDescription = JSON.parse(item.news_description);
            // If parsing succeeds, create EditorState from the parsed data
            if (parsedDescription) {
              newsDescriptionEditorState = EditorState.createWithContent(convertFromRaw(parsedDescription));
            }
          } catch (error) {
            // Log parsing errors
            console.error('Error parsing news_description:', error);
          }
          console.log('Parsed news_description EditorState:', newsDescriptionEditorState);
          return {
            ...item,
            date: new Date(item.date).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
            news_description: newsDescriptionEditorState
          };
        });
        setNews(formattedNews);
      })
      .catch(err => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${ServerUrl}/admin/NEWS/` + id)
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='app'>
      <Sidebar />
      <div className="admin-content-view-container">
        <div className="header">
          <h1>Blogs</h1>
          <Link to="/admin/createnews" className="create-more-content-button">
            <FaPlus />
            Create Blog
          </Link>
        </div>
        <div className="content-body-container">
          {news.map((item, index) => (
            <div className="content-view-item" key={index}>
              <div className="view-content-image-container">
                <img src={`${ServerUrl}/images/${item.newsimage}`} alt="News" />
              </div>
              <div className="view-content-details">
                <h2>{item.news_title}</h2>
                <p style={{ paddingTop: '10px' }}>{item.headline}</p>
                {/* <Editor editorState={item.news_description} readOnly={true} />*/}

                <div className="content-actions">
                  <p style={{ paddingBottom: '10px' }}>{item.date}</p>
                  <Link to={`/admin/editnews/${item.news_id}`} className="content-edit-button">
                    <FaEdit />
                  </Link>
                  <button className="content-delete-button" onClick={e => handleDelete(item.news_id)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Admin_NEWS;
